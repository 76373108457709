import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ToastContainer from "./components/ToastContainer";
import { ThemeProvider } from "./contexts/ThemeContext";
import AppLayout from "./pages/AppLayout";
import CreateUserPage from "./pages/CreateUserPage";
import EditUserPage from "./pages/EditUserPage";
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./pages/ProtectedRoute";
import StudioPage from "./pages/StudioPage";
import UsersPage from "./pages/UsersPage";
import WhatsAppAnalytics from "./pages/WhatsAppAnalytics";
import ProfilePage from "./pages/ProfilePage";

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<AppLayout />}>
              {/* DEFAULT INDEX ROUTE */}
              <Route index element={<WhatsAppAnalytics />} />
              {/* ANALYTICS */}
              <Route path="/analytics" element={<WhatsAppAnalytics />} />
              {/* USERS */}
              <Route path="members" element={<UsersPage />} />
              <Route path="members/create" element={<CreateUserPage />} />
              <Route path="members/edit/:id" element={<EditUserPage />} />
              {/* STUDIOS */}
              <Route path="studios" element={<StudioPage />} />
              {/* Profile */}
              <Route path="profile" element={<ProfilePage />} />
              {/* GROUPS */}
              {/* <Route path="groups" element={<GroupsPage />} />
              <Route path="groups/create" element={<CreateGroupPage />} />
              <Route path="groups/edit/:id" element={<EditGroupPage />} /> */}
            </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
