import React, { useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { rootStore } from "../stores/RootStore";
import { useConfirmationModal } from "../components/ConfirmationModal";
import { UserUpdateDTO } from "../backend-types";
import PasswordChangeComponent from "../components/PasswordChange";
import { handleError } from "../errorHandler";

const EditUserPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { userStore, authStore, toastStore } = rootStore;
  const { showConfirmationModal, ConfirmationModalComponent } =
    useConfirmationModal();
  const [user, setUser] = useState<UserUpdateDTO>({
    email: "",
    firstname: "",
    lastname: "",
    roleId: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeData = async () => {
      if (!id) {
        toastStore.add({
          type: "error",
          caption: "Fehler",
          message: "Keine Nutzer-ID gefunden.",
        });
        navigate("/members");
        return;
      }

      setIsLoading(true);
      try {
        await authStore.fetchRoles();
        const userData = await userStore.getUser(id);
        const roleId =
          authStore.roles.find((role) => role.roleName === userData.roles[0])
            ?.id || "";
        setUser({
          email: userData.email,
          firstname: userData.firstname,
          lastname: userData.lastname,
          roleId: roleId,
        });
      } catch (error) {
        const { type, message } = handleError(error, "Nutzer laden");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to load user:", error);
        navigate("/members");
      } finally {
        setIsLoading(false);
      }
    };

    initializeData();
  }, [id, userStore, authStore, navigate]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!id) return;

    try {
      await userStore.updateUser(id, user);
      toastStore.add({
        type: "success",
        caption: "Erfolg",
        message: "Nutzer wurde erfolgreich aktualisiert",
      });
      navigate("/members");
    } catch (error) {
      const { type, message } = handleError(error, "Nutzer aktualisieren");
      toastStore.add({ type, caption: "Fehler", message });
      console.error("Failed to update user:", error);
    }
  };

  const handleNavigation = useCallback(
    async (path: string) => {
      const originalUser = await userStore.getUser(id || "");
      const originalRoleId =
        authStore.roles.find((role) => role.roleName === originalUser.roles[0])
          ?.id || "";

      const hasUnsavedChanges =
        user.email !== originalUser.email ||
        user.firstname !== originalUser.firstname ||
        user.lastname !== originalUser.lastname ||
        user.roleId !== originalRoleId;

      if (hasUnsavedChanges) {
        const confirmed = await showConfirmationModal({
          title: "Ungespeicherte Änderungen",
          text: "Sie haben ungespeicherte Änderungen. Möchten Sie die Seite trotzdem verlassen?",
          confirmText: "Verlassen",
          cancelText: "Abbrechen",
        });
        if (confirmed) navigate(path);
      } else {
        navigate(path);
      }
    },
    [user, navigate, showConfirmationModal, id, userStore, authStore.roles]
  );

  const availableRoles = authStore.roles.filter(
    (role) => role.roleName === "USER" || role.roleName === "MANAGER"
  );

  if (isLoading) {
    return (
      <div className="flex h-full w-full justify-center items-center">
        <span>Loading...</span>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full justify-center">
      <ConfirmationModalComponent />
      <div className="flex flex-col mt-4 gap-4 w-7/12 h-full">
        <span className="text-xl font-bold">Nutzer bearbeiten</span>
        <div className="flex flex-col px-4 border border-neutral rounded">
          <form onSubmit={handleSubmit}>
            <table className="table table-fixed w-full">
              <colgroup>
                <col className="w-4" />
                <col className="w-4" />
              </colgroup>
              <tbody>
                {/* Email */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>E-Mail</span>
                  </td>
                  <td>
                    <div>
                      <input
                        type="email"
                        name="email"
                        value={user.email}
                        onChange={handleChange}
                        className="input input-sm input-bordered w-full rounded"
                        required
                      />
                    </div>
                  </td>
                </tr>
                {/* First Name */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Vorname</span>
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        name="firstname"
                        value={user.firstname}
                        onChange={handleChange}
                        className="input input-sm input-bordered w-full rounded"
                        required
                      />
                    </div>
                  </td>
                </tr>
                {/* Last Name */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Nachname</span>
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        name="lastname"
                        value={user.lastname}
                        onChange={handleChange}
                        className="input input-sm input-bordered w-full rounded"
                        required
                      />
                    </div>
                  </td>
                </tr>
                {/* Role */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Rolle</span>
                  </td>
                  <td>
                    <div>
                      <select
                        name="roleId"
                        value={user.roleId}
                        onChange={handleChange}
                        className="select select-sm select-bordered w-full rounded"
                        required
                      >
                        <option value="">Rolle auswählen</option>
                        {availableRoles.map((role) => (
                          <option key={role.id} value={role.id}>
                            {role.roleName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </td>
                </tr>
                {/* Controls */}
                <tr>
                  <td colSpan={2}>
                    <div className="flex gap-4 py-4">
                      <button
                        type="button"
                        className="btn btn-neutral"
                        onClick={() => handleNavigation("/members")}
                      >
                        Abbrechen
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={
                          !user.email ||
                          !user.firstname ||
                          !user.lastname ||
                          !user.roleId
                        }
                      >
                        Änderungen speichern
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        <PasswordChangeComponent userId={id} />
      </div>
    </div>
  );
});

export default EditUserPage;
