import { useNavigate } from "react-router-dom";

const SidebarHeader = () => {
  const navigate = useNavigate();
  return (
    <div
      className="flex items-center space-x-3 p-4 rounded-lg cursor-pointer"
      onClick={() => navigate("/analytics")}
    >
      <div className="avatar">
        <div className="w-8 h-8 rounded-md bg-gradient-to-r from-purple-500 to-indigo-500 flex items-center justify-center"></div>
      </div>
      <div className="flex">
        <span className="text-xl font-semibold">Performance</span>
        <span className="text-xl text-primary font-semibold">.OS</span>
      </div>
    </div>
  );
};

export default SidebarHeader;
