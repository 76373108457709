import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StudioDTO } from "../backend-types";
import Badge from "../components/Badge";
import EmptyState from "../components/EmptyState";
import RefreshButton from "../components/RefreshButton";
import SearchBar from "../components/SearchBar";
import { handleError } from "../errorHandler";
import { rootStore } from "../stores/RootStore";

const StudioPage: React.FC = observer(() => {
  const { customerStore, groupStore, toastStore } = rootStore;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(
    searchParams.get("studio") || ""
  );

  useEffect(() => {
    groupStore.refreshGroups();
    customerStore.refreshCustomer();
  }, [customerStore, groupStore]);

  const handleRefreshStudios = async () => {
    try {
      await customerStore.refreshCustomer();
    } catch (error: any) {
      const { type, message } = handleError(error, "Studios abrufen");
      toastStore.add({ type, caption: "Fehler", message });
      console.error("Failed to refresh studios (customer endpoint):", error);
    }
  };

  const filteredStudios = useMemo(() => {
    if (!searchTerm.trim()) return customerStore.studios;

    const searchLower = searchTerm.toLowerCase().trim();

    return customerStore.studios.filter((studio) => {
      const searchableText = [
        studio.name,
        studio.street,
        studio.zip,
        studio.city,
        studio.country,
        ...studio.groups.map((g) => g.groupName),
      ]
        .join(" ")
        .toLowerCase();

      return searchableText.includes(searchLower);
    });
  }, [customerStore.studios, searchTerm]);

  const renderStudioRow = (studio: StudioDTO) => (
    <tr key={studio.id} className="border-b border-neutral last:border-b-0">
      {/* NAME and EMAIL */}
      <td>
        <div className="flex-grow min-w-0 mr-2">
          <div className="text-sm font-bold truncate" title={studio.name}>
            {studio.name}
          </div>
          <div
            className="text-xs text-neutral-500 truncate"
            title={studio?.email}
          >
            {studio.street}, {studio.zip} {studio.city}, {studio.country}
          </div>
        </div>
      </td>
      {/* GROUP */}
      <td>
        <div className="text-sm truncate" title="GROUP">
          {studio.groups.map((g) => (
            <div
              key={g.id}
              className="cursor-pointer"
              onClick={() => navigate("/groups/edit/" + g.id)}
            >
              <Badge text={g.groupName} />
            </div>
          ))}
        </div>
      </td>
    </tr>
  );

  return (
    <div className="w-full h-fit flex justify-center">
      <div className="flex flex-col mt-4 gap-4 w-7/12">
        {/* PAGE HEADER */}
        <div className="flex gap-2 items-center">
          <span className="text-2xl font-bold">Studios</span>
          <RefreshButton
            onClick={() => customerStore.refreshCustomer()}
            showLabel={false}
          />
        </div>
        {/* FILTER SECTION*/}
        <div className="flex gap-2 w-fit">
          <SearchBar
            onSearch={setSearchTerm}
            placeholder="Studios suchen"
            paramName="studio"
          />
        </div>
        {/* TABLE */}
        <div className="border border-neutral rounded p-4">
          {filteredStudios.length ? (
            <div>
              {/* TODO: Use loading spinner */}
              {customerStore.isLoading ? (
                <div className="text-center">Loading...</div>
              ) : (
                <div className="max-h-[calc(100vh-300px)] overflow-y-auto">
                  <table className="table w-full">
                    <colgroup>
                      <col className="w-[40%]" />
                      <col className="w-[30%]" />
                      <col className="w-[10%]" />
                    </colgroup>
                    <thead className="sticky top-0 bg-base-100">
                      <tr className="border-b border-neutral">
                        <th className="text-left">Name</th>
                        <th className="text-left">Gruppe</th>
                      </tr>
                    </thead>
                    <tbody>{filteredStudios.map(renderStudioRow)}</tbody>
                  </table>
                </div>
              )}
            </div>
          ) : (
            <EmptyState
              message={
                searchTerm
                  ? "Keine Studios gefunden!"
                  : "Keine Studios verfügbar!"
              }
              onRefresh={handleRefreshStudios}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default StudioPage;
