import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { rootStore } from "../stores/RootStore";

const LoginPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authStore } = rootStore;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const from = location.state?.from?.pathname || "/analytics";

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await authStore.login(email, password);
      navigate(from, { replace: true });
    } catch (err) {
      setError("Login failed. Please check your credentials.");
    }
  };

  return (
    <div className="min-h-screen bg-base-200 flex items-center justify-center">
      <div className="card w-full max-w-sm shadow-2xl bg-base-100">
        <div className="flex justify-center pt-8">
          <div className="flex items-center">
            <span className="text-xl font-semibold">Performance</span>
            <span className="text-xl text-primary font-semibold">.OS</span>
          </div>
        </div>
        <form onSubmit={handleLogin} className="card-body">
          <div className="form-control">
            <label htmlFor="email" className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              id="email"
              type="email"
              placeholder="Email"
              className="input input-bordered"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-control">
            <label htmlFor="password" className="label">
              <span className="label-text">Password</span>
            </label>
            <input
              id="password"
              type="password"
              placeholder="Passwort"
              className="input input-bordered"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && (
            <div className="text-error mt-2 text-sm text-center">{error}</div>
          )}
          <div className="form-control mt-6">
            <button type="submit" className="btn btn-primary">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default LoginPage;
