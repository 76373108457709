import { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronRightIcon, Cog8ToothIcon } from "@heroicons/react/24/outline";

const SidebarSettingsControl = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const navigationItems = [
    { to: "/members", label: "Nutzer" },
    { to: "/studios", label: "Studios" },
    // { to: "/groups", label: "Gruppen" },
  ];

  const linkClasses = "flex py-2 px-4 hover:bg-neutral rounded text-sm";

  return (
    <div className="mx-4">
      <div
        className="flex py-2 px-4 cursor-pointer justify-between items-center"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex gap-2 items-center">
          <input
            type="checkbox"
            checked={isExpanded}
            onChange={() => setIsExpanded(!isExpanded)}
            className="hidden"
          />
          <Cog8ToothIcon className="size-5" />
          <span className="text-">Einstellungen</span>
        </div>
        <ChevronRightIcon
          className={`size-4 transition-transform duration-200 ${
            isExpanded ? "rotate-90" : ""
          }`}
        />
      </div>
      <div
        className={`overflow-hidden transition-all duration-250 ease-in-out ${
          isExpanded ? "max-h-48 opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <ul className="ml-4 mr-4">
          {navigationItems.map((item) => (
            <li key={item.to}>
              <Link to={item.to} className={linkClasses}>
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SidebarSettingsControl;
