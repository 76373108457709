import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { rootStore } from "../stores/RootStore";
import { handleError } from "../errorHandler";

interface PasswordChangeComponentProps {
  userId?: string; // Optional for creation page
  isSelfChange?: boolean;
  onPasswordChange?: (newPassword: string) => void; // Callback for creation page
  onCancel?: () => void;
  showControls?: boolean; // Whether to show the buttons
}

const PasswordChangeComponent: React.FC<PasswordChangeComponentProps> =
  observer(
    ({
      userId,
      isSelfChange = false,
      onPasswordChange,
      onCancel,
      showControls = true,
    }) => {
      const { userStore, toastStore } = rootStore;
      const [passwordData, setPasswordData] = useState({
        id: userId || "",
        oldPassword: "",
        newPassword: "",
      });
      const [confirmPassword, setConfirmPassword] = useState("");

      useEffect(() => {
        setPasswordData((prev) => ({ ...prev, id: userId || "" }));
      }, [userId]);

      const resetForm = () => {
        setPasswordData({
          id: userId || "",
          oldPassword: "",
          newPassword: "",
        });
        setConfirmPassword("");
      };

      const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (passwordData.newPassword !== confirmPassword) {
          toastStore.add({
            type: "error",
            caption: "Fehler",
            message: "Die Passwörter stimmen nicht überein.",
          });
          return;
        }

        try {
          if (onPasswordChange) {
            // For creation page
            onPasswordChange(passwordData.newPassword);
            resetForm();
          } else if (userId) {
            // For password change
            await userStore.changePassword(passwordData);
            toastStore.add({
              type: "success",
              caption: "Erfolg",
              message: "Passwort wurde erfolgreich geändert",
            });
            resetForm();
          }
        } catch (error) {
          const { type, message } = handleError(error, "Passwort ändern");
          toastStore.add({ type, caption: "Fehler", message });
          console.error("Failed to change password:", error);
        }
      };

      return (
        <form onSubmit={handleSubmit}>
          <span className="text-xl font-bold">Passwort ändern</span>
          <div className="mt-4 px-4 border border-neutral rounded">
            <table className="table table-fixed w-full">
              <colgroup>
                <col className="w-4" />
                <col className="w-4" />
              </colgroup>
              <tbody>
                {/* Only show old password field for self-change */}
                {isSelfChange && (
                  <tr className="border-b border-neutral">
                    <td>
                      <span>Aktuelles Passwort</span>
                    </td>
                    <td>
                      <div>
                        <input
                          type="password"
                          value={passwordData.oldPassword}
                          onChange={(e) =>
                            setPasswordData((prev) => ({
                              ...prev,
                              oldPassword: e.target.value,
                            }))
                          }
                          className="input input-sm input-bordered w-full rounded"
                          required={isSelfChange}
                        />
                      </div>
                    </td>
                  </tr>
                )}
                {/* New Password */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Neues Passwort</span>
                  </td>
                  <td>
                    <div>
                      <input
                        type="password"
                        value={passwordData.newPassword}
                        onChange={(e) =>
                          setPasswordData((prev) => ({
                            ...prev,
                            newPassword: e.target.value,
                          }))
                        }
                        className="input input-sm input-bordered w-full rounded"
                        required
                      />
                    </div>
                  </td>
                </tr>
                {/* Confirm Password */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Passwort bestätigen</span>
                  </td>
                  <td>
                    <div>
                      <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="input input-sm input-bordered w-full rounded"
                        required
                      />
                    </div>
                  </td>
                </tr>
                {/* Controls - Only show if showControls is true */}
                {showControls && (
                  <tr>
                    <td colSpan={2}>
                      <div className="flex gap-4 py-4">
                        {onCancel && (
                          <button
                            type="button"
                            className="btn btn-neutral"
                            onClick={onCancel}
                          >
                            Abbrechen
                          </button>
                        )}
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={
                            !passwordData.newPassword ||
                            !confirmPassword ||
                            (isSelfChange && !passwordData.oldPassword) ||
                            passwordData.newPassword !== confirmPassword
                          }
                        >
                          {userId ? "Passwort ändern" : "Passwort setzen"}
                        </button>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </form>
      );
    }
  );

export default PasswordChangeComponent;
